import { GetServerSideProps, NextPage } from 'next';
import { HeroTop, LandingPage } from '@/payload-types';

import BasicContainer from '@/components/Containers/BasicContainer';
import Cookies from 'js-cookie';
import Head from 'next/head';
import { RenderBlocks } from '@/payload/RenderBlocks';
import axios from 'axios';
import { useAccountState } from '@/context/accountContext';
import { useRouter } from 'next/router';

interface PageProps {
  layout: HeroTop[] | null;
  includeOfferIds: LandingPage['accessOfferIds'];
}

const Page: NextPage<PageProps> = ({ layout, includeOfferIds }) => {
  const router = useRouter();
  const account = useAccountState();

  if (router.isFallback) {
    return null;
  }

  if (account?.auth?.token) {
    router.push({
      pathname: '/filmer',
      query: router.query,
    });
  }

  return (
    <>
      <Head>
        <title>Draken Film</title>
        <meta name="pageType" content="default-title___sv" />
      </Head>
      <BasicContainer>
        {layout && <RenderBlocks blocks={layout} authUser={account?.auth} includeIds={includeOfferIds} />}
      </BasicContainer>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  let props = {};

  const {
    data: { layout, accessOfferIds },
  } = await axios.get<LandingPage>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/globals/landing-page?locale=undefined&draft=false&depth=1s`
  );

  if (layout) {
    props = { ...props, layout, accessOfferIds };
  } else {
    props = { ...props, layout: null, accessOfferIds };
  }

  return { props };
};

export default Page;
